import React from "react";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplayIcon from "@material-ui/icons/Replay";

import { LocalizedText } from "../LocalizedText";
import { useTranslation } from "../../hooks/i18n";
import { LocalizedButton } from "../Button";
import { MediaStatuses, MediaTypes } from "../EventDetail/constants";
import { EventCameraMediaViewProps } from "./interfaces";
import { useStyles } from "./styles";
import { userHasSeenMediaDialog, setUserHasSeenMediaDialog } from "./services";
import { RequestedTooltip, RequestMediaModal } from "../RequestMediaModal";
import { TooltipPopover } from "../TooltipPopover";
import { ImpersonationContext } from "../../contexts/ImpersonationContext";
import { UserContext } from "../../contexts/UserContext";
import { hasAccessToCustomerMedia } from "./utils";
import { DateTime } from "luxon";
import { OutOfTrialDialogView } from "../OutOfTrialDialog/views";

/**
 * Displays media from a camera and provides controls
 * to request more detailed media (e.g. video)
 */
export const EventCameraMediaView = ({
  media,
  eventId,
  vehicleId,
  channel,
  cameraName,
  onRequestImage,
  onRequestVideo,
  highlight,
  currentUserId,
  showTools,
  account,
}: EventCameraMediaViewProps): JSX.Element => {
  const classes = useStyles({ highlight });
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const { iUser } = React.useContext(ImpersonationContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const { ctxUser } = React.useContext(UserContext);
  const [showOutOfTrialModal, setShowOutOfTrialModal] = React.useState(false);

  /**
   * Handlers for managing popovers for info about poending media
   * @see https://material-ui.com/components/popover/#mouse-over-interaction
   */
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  /**
   * Determine which media are available for
   * immediate display
   */
  const thumb = media.find(({ mediaType }) => mediaType === MediaTypes.thumb);
  const image = media.find(({ mediaType }) => mediaType === MediaTypes.image);
  const video = media.find(({ mediaType }) => mediaType === MediaTypes.video);
  const thumbAvailable = thumb?.status === MediaStatuses.available;
  const imageAvailable = image?.status === MediaStatuses.available;
  const videoAvailable = video?.status === MediaStatuses.available;
  const noMedia = !thumbAvailable && !imageAvailable && !videoAvailable;
  const showThumb = thumbAvailable && !imageAvailable;
  const showImage = imageAvailable && !videoAvailable;
  const showVideo = videoAvailable;

  /**
   * Determine which media are pending
   */
  const videoPending = video?.status === MediaStatuses.pending;

  /**
   * Determine which media are unavailable
   */
  const videoUnavailable =
    video?.status === MediaStatuses.unavailable &&
    video?.attempts !== undefined &&
    video.attempts === 3;

  /**
   *  Retry available media if unavailable and attempts < 3
   */
  const retryAvailable =
    video?.status === MediaStatuses.unavailable &&
    video?.attempts !== undefined &&
    video.attempts < 3;

  /**
   * Determine button label based on media statuses
   */
  let buttonLabel = "request_video"; // If none of the conditions below are true then we must need to request an image

  const outOfTrial =
    account &&
    ["RAM"].includes(account.providers[0]) &&
    account?.packageType === "CORE" &&
    account.trialStartDate &&
    DateTime.fromISO(account.trialStartDate).diffNow("days").days <= -30;

  if (videoPending) {
    buttonLabel = "video_requested";
  }

  /**
   * Determine popover text based on media statuses
   */
  let popoverContent = null;
  if (videoPending) {
    const requestedBy = video?.requestedBy;
    popoverContent = (
      <RequestedTooltip
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the API guarantees that if media has been requested this property will be present
        firstName={requestedBy!.firstName}
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
        lastName={requestedBy!.lastName}
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
        isCurrentUser={currentUserId === requestedBy!.id}
      />
    );
  }

  if (retryAvailable) {
    popoverContent = <LocalizedText text="media_requestable_hint" />;
    buttonLabel = "retry";
  }

  if (videoUnavailable)
    popoverContent = <LocalizedText text="media_unavailable_hint" />;

  /**
   * Handle media requests
   */
  // const handleRequestImage = () => {
  //   if (userHasSeenMediaDialog()) {
  //     onRequestImage(channel);
  //     return;
  //   }

  //   setShowModal(true);
  // };
  const handleRequestVideo = () => {
    if (outOfTrial) {
      setShowOutOfTrialModal(true);
      return;
    }
    if (userHasSeenMediaDialog()) {
      onRequestVideo(channel, vehicleId, eventId);
      return;
    }

    setShowModal(true);
  };

  const thumbUrl = thumb?.link || "";
  const imageUrl = image?.link || "";
  const videoUrl = video?.link || "";

  /**
   * Build media request/status UI based on status
   */
  const mediaUi = () => {
    /**
     * Impersonation user cannot see or download media
     */

    /**
     * Video available and requested
     */
    if (videoPending) {
      return (
        <Alert
          className={classes.alert}
          severity="info"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <LocalizedText text="video_requested" />
        </Alert>
      );
    }

    /**
     * Image shown, video not yet requested and unavailable
     */
    if (videoUnavailable) {
      return (
        <Alert
          className={classes.alert}
          severity="warning"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <LocalizedText text="video_unavailable" />
        </Alert>
      );
    }

    if (retryAvailable) {
      return (
        <LocalizedButton onClick={handleRequestVideo}>
          {buttonLabel}
          <ReplayIcon />
        </LocalizedButton>
      );
    }

    /**
     * Image shown, video available but not yet requested
     */
    if (!videoPending && !videoAvailable) {
      return (
        <LocalizedButton onClick={handleRequestVideo}>
          {buttonLabel}
        </LocalizedButton>
      );
    }

    /**
     * Video shown, but impersonated user cannot download
     */
    if (showVideo && !iUser) {
      return (
        <LocalizedButton
          role="button"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- MUI button typings don't seem to include `component`
          // @ts-ignore
          component="a"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- when showing video, video will be defined and contain a link
          href={video!.link!}
          download
        >
          download_video
          <GetAppIcon />
        </LocalizedButton>
      );
    }
  };

  const canViewVideo =
    !iUser || (iUser && hasAccessToCustomerMedia(ctxUser?.email));

  return (
    <Paper className={classes.card} data-testid="event-camera-media">
      <div className={classes.media}>
        {noMedia && !iUser && (
          <Alert severity="info">
            <LocalizedText text="media_not_requested" />
          </Alert>
        )}

        {!canViewVideo && !showThumb && !showImage && (
          <Alert severity="info">
            <LocalizedText text="impersonate_cannot_see_media" />
          </Alert>
        )}

        {showThumb && <img alt={t("event_thumb")} src={thumbUrl} />}
        {showImage && <img alt={t("event_image")} src={imageUrl} />}
        {showVideo && canViewVideo && (
          <video
            aria-label={t("event_video")}
            src={videoUrl}
            preload="auto"
            poster={imageUrl}
            controls
          />
        )}
      </div>

      <div className={classes.toolbar}>
        <span>{cameraName}</span>

        {showTools && mediaUi()}
        <TooltipPopover
          id="pending_media_popover"
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {popoverContent}
        </TooltipPopover>
      </div>

      {showModal && (
        <RequestMediaModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={(dontShowAgain: boolean) => {
            onRequestVideo(channel, vehicleId, eventId);
            setShowModal(false);
            if (dontShowAgain) setUserHasSeenMediaDialog();
          }}
        />
      )}
      <OutOfTrialDialogView
        show={showOutOfTrialModal}
        onClose={() => setShowOutOfTrialModal(false)}
        salesforceId={account?.salesforceId || ""}
        DialogType={"OUT_OF_TRIAL"}
      />
    </Paper>
  );
};
